/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-23 15:00:36
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-30 11:34:04
 */
import { createNamespacedHelpers, mapGetters as mapRootGetters } from 'vuex'
import { moneyFormat } from '@/plugins/utils'

const { mapActions } = createNamespacedHelpers('PayOrder')

export default {
  name: 'OrderPay',
  data () {
    return {
      timer: null,
      timeShow: '30:00'
    }
  },
  computed: {
    ...mapRootGetters([
      'payOrderInfo'
    ]),

    // 订单详情
    orderDetail () {
      return this.payOrderInfo.detail || {}
    },

    // 订单金额
    orderCash () {
      const money = this.orderDetail.amount
      if (!money) {
        return 0
      }
      return moneyFormat(money / 100)
    },

    // 价格
    orderPrice () {
      const money = this.orderDetail.price
      if (!money) {
        return 0
      }
      return moneyFormat(money / 100)
    },

    endTimeStamp () {
      const { order_avaliable_time: endTimeStamp = null } = this.orderDetail
      return endTimeStamp
    }
  },
  async mounted () {
    await this.getOrderDetail()
    if (this.endTimeStamp) {
      this.startCount()
    }
    // 检查支付状态
    const { toAliPay, from } = this.payOrderInfo
    if (toAliPay && from) {
      // 显示支付状态弹窗
      await this.updatePayStatus({ toAliPay: false })
      await this.showOrderFinish()
      // 回到上一页
      this.$router.push(from)
    }
  },
  destroyed () {
    // 清除倒计时
    clearTimeout(this.timer)
    this.timer = null
  },
  methods: {
    ...mapActions([
      'getOrderDetail',
      'payCash',
      'updatePayStatus',
      'showOrderFinish'
    ]),

    // 金钱格式化
    moneyFormat,

    /**
     * 立即支付
     */
    async payAction () {
      const { from } = this.payOrderInfo
      const returnUrl = from || location.href
      const form = await this.payCash({ return_url: returnUrl })
      this.$refs.payBtn.updatedLoading(false)
      let alipay = document.querySelector('#idatabright-alipay')
      if (alipay === null) {
        alipay = document.createElement('div');
        alipay.id = 'idatabright-alipay'
        document.querySelector('body').appendChild(alipay)
      }
      alipay.innerHTML = form.replace(/<script>(.*)<\script>$/, '');
      // 显示支付状态弹窗
      await this.updatePayStatus({ toAliPay: true })
      // 主动调起支付
      alipay.querySelector('form').submit()
    },

    /**
     * 开始倒计时
     */
    startCount () {
      this.doCount()
    },

    /**
     * 倒计时计数
     */
    doCount () {
      console.log('counting')
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        const nowTimeStamp = new Date().getTime()
        const deltTimeStamp = this.endTimeStamp - nowTimeStamp
        this.timeShow = this.transformDelt(deltTimeStamp)
        if (this.timeShow === '00:00') {
          clearTimeout(this.timer)
          this.timer = null
          return
        }
        this.doCount()
      }, 1000)
    },

    /**
     * 转时间
     */
    transformDelt (deltTimeStamp) {
      if (deltTimeStamp <= 0) {
        return '00:00'
      }
      const mins = parseInt(deltTimeStamp / 60000, 10)
      const secs = parseInt((deltTimeStamp % 60000) / 1000, 10)
      return [mins, secs].map(i => i > 9 ? i : '0' + i).join(':')
    }
  }
}
