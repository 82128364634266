/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-23 15:01:03
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-23 15:05:32
 */
import PayOrder from './PayOrder.vue'
export default PayOrder
